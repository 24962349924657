<template>
  <b-table-simple>
    <b-tbody>
      <b-tr>
        <b-th class="text-right" style="width: 35%">付与開始日</b-th>
        <b-td>{{start_date}}</b-td>
      </b-tr>
      <b-tr>
        <b-th class="text-right">付与終了日</b-th>
        <b-td>{{end_date}}</b-td>
      </b-tr>
    </b-tbody>
      <b-tr>
        <b-th class="text-right">繰越日数</b-th>
        <b-td>{{carryover_days}}</b-td>
      </b-tr>
      <b-tr>
        <b-th class="text-right">残り日数(繰越含む)</b-th>
        <b-td>{{days}}</b-td>
      </b-tr>
        <b-tr>
            <b-th class="text-right">未承認日数</b-th>
            <b-td>{{unapproved_days}}</b-td>
        </b-tr>
      <b-tr>
        <b-th class="text-right">次回付与日数</b-th>
        <b-td>{{next_days}}</b-td>
      </b-tr>
      <b-tr>
        <b-th class="text-right">備考</b-th>
        <b-td style="max-width: 200px; white-space: pre-wrap; word-wrap: break-word;">{{comment}}</b-td>
      </b-tr>
  </b-table-simple>
</template>
<script>
import { ajax, config, constant } from '../../../utils'
export default {
  data() {
    return {
      start_date: null,
      end_date: null,
      days: null,
      carryover_days: null,
      unapproved_days: null,
      next_days: null,
      comment: null,
    }
  },
  created() {
    this.getPaidVacationRemainDays();
  },
  methods: {
    getPaidVacationRemainDays() {
      ajax.fetchGet(config.api.employee.paid_vacation_status).then(data => {
        this.start_date = data.start_date === null ? constant.ERROR.NO_PAID_VACATION : data.start_date;
        this.end_date = data.end_date === null ? constant.ERROR.NO_PAID_VACATION : data.end_date;
        this.days = data.days === null ? constant.ERROR.NO_PAID_VACATION : data.days;
        this.carryover_days = data.carryover_days === null ? constant.ERROR.NO_PAID_VACATION : data.carryover_days;
        this.unapproved_days = data.unapproved_days === null ? constant.ERROR.NO_PAID_VACATION : data.unapproved_days;
        this.next_days = data.next_days === null ? constant.ERROR.NO_PAID_VACATION : data.next_days;
        this.comment = data.comment;
      })
    },
  },
}
</script>
